import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { DataLayerService } from '@services/data-layer.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Free PDF App';
  extension = environment.extension;
  constructor(
    private titleService: Title,
    private dataLayerService: DataLayerService,
  ) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit() {}

  onClick(event, category) {
    this.dataLayerService.generateEvent('ga_event','header_ads_link', category + '_click', null);
    event.preventDefault();
  }
}
