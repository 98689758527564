import { Component, ViewChild, ElementRef } from '@angular/core';
import { UploadService } from  '@services/upload.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { DataLayerService } from '@services/data-layer.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-converter',
  templateUrl: './converter.component.html',
  styleUrls: ['./converter.component.scss']
})
export class ConverterComponent {
  // progressChanged: EventEmitter<string> = new EventEmitter();
  @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef
  // @ViewChild('progressLabel', {static: false}) progressLabel: ElementRef
  @ViewChild('progressStatus', {static: false}) progressStatus: ElementRef
  // @ViewChild('progressBar', {static: false}) progressBar: ElementRef
  private _progress:string = 'start'
  private progress_list = ['start','loading','cancel','multi','error','alert','finished']
  yesBttn: string = "yes"
  noBttn: string = "no"
  chooseFileBttn: string = "Choose Files"
  startOver: string = "Start over"
  reloadIcon: string = "reload"
  documentIcon: string = "document"
  sub: Array<Subscription> = [];
  intervalRef = [];
  inProgress:boolean = false;
  rejected: File[] = [];
  rejected_ext: String[] = []
  rejected_error: string = null;
  files  = []
  extension = environment.extension;
  popupStatus: boolean = false;
  constructor(
    private uploadService: UploadService,
    private dataLayerService: DataLayerService,
    private router:Router
  ) {
      this.router.events.subscribe(event=> {
        if (event instanceof NavigationEnd) {
          this.dataLayerService.generatePageView(event.url)
        }
      });
  }

  private uploadFiles() {
    this.progress = 'loading'
    
    const response = this.uploadService.initConvert(this.files);
    // console.log(response)
    if(response.error) {
      this.clearScreen(response.error)
    } else {
      const s = response.subscribtion.subscribe(result => {
        if(this.progress == 'loading') {
          if (result['success'] == true) {
            this.dataLayerService.generateEvent('ga_event','download','success', '');
            this.downloadFile(result)
            Object.values(response.rejected).forEach((file:File, index)=> {
              this.rejected_ext.push(file.name.split('.').pop())
            });
            this.rejected_ext = this.rejected_ext.filter((v, i, a) => a.indexOf(v) === i);
            this.setRejectedMessage();
          } else {
            this.dataLayerService.generateEvent('ga_event','download','error', '');
            this.clearScreen('error')
          }
        }
      })
      this.sub.push(s)
    }
  }

  setRejectedMessage() {
    switch(true) {
      case this.rejected_ext.length === 1:
        this.rejected_error = `* File format .${this.rejected_ext[0]} is not supported`
        break;
      case this.rejected_ext.length === 2:
        this.rejected_error = `* File formats .${this.rejected_ext[0]} and .${this.rejected_ext[1]} are not supported`
        break;
      case this.rejected_ext.length > 2:
        this.rejected_error = `* File formats .${this.rejected_ext[0]}, .${this.rejected_ext[1]} and more are not supported`
        break;
      default:
        this.rejected_error = null;
        break;
    }
  }

  clearScreen(progress:string) {
    // console.log('progress', progress);
    if(progress && this.progress_list.includes(progress)) {
      this.progress = progress
    }
    
    this.rejected_ext = [];
    this.files = [];
    this.reloadProgress();
    this.setRejectedMessage();
    this.fileUpload.nativeElement.value = null;
  
    Object.values(this.sub).forEach((value:Subscription, index)=> {
      value.unsubscribe()
    })
    this.sub = []

    while(this.intervalRef.length) {
      // console.log('interval clear');
      this.intervalRef.forEach(element => clearInterval(element));
      this.intervalRef.pop()
    }
    this.inProgress = false;
  }

  private reloadProgress() {
    // console.log('reloadProgress');
    // this.progressBar.nativeElement.style.width = "0%";
    // this.progressLabel.nativeElement.innerHTML = "0%";
    this.progressStatus.nativeElement.innerHTML = 'Converting...';
  }

  set progress(val: string) {
    this._progress = val;
    if(val == 'loading' &&  ! this.inProgress) {
      this.loading();
    }
  }

  get progress(): string {
    return this._progress;
  }

  showSecurityPopup(status: boolean) {
    this.popupStatus = status;
  }

  private downloadFile(file) {
    const inIFrame = (window.location != window.parent.location);
    if (inIFrame) {
      window.parent.postMessage(
        {
          event_id: 'conversionComplete',
          params: {
            url: file["file"]["url"],
            name: file["file"]["filename"]
          }
        },
        "*"
      );
    }
    else {
      window.location = file["file"]["url"];
    }
    this.files = []
    this.progress = 'finished'
  }

  loading() {
    // progress bar for display only
    this.inProgress = true;
    let ref;
    let width = 0;
    // let progressBar = this.progressBar.nativeElement;
    // let progressLabel = this.progressLabel.nativeElement;
    let progressStatus = this.progressStatus.nativeElement;
    ref = setInterval(function () {
      // console.log(width);
      if (width == 100) {
        clearInterval(ref)
        progressStatus.innerHTML = 'Downloading...';
      }
      else {
        width++;
        // progressBar.style.width = width + "%";
        // progressLabel.innerHTML = width  + "%";
      }
    }, 100);
    this.intervalRef.push(ref)
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.click();
    fileUpload.onchange = () => {
      if(fileUpload.files.length > 0) {
        this.dataLayerService.generateEvent('ga_event','click','choose', '');
        this.files.push(...fileUpload.files);
        this.uploadFiles();
      }
    }
  }

  onDrop(event) {
    if(event.addedFiles) {
      if(event.addedFiles.length > 0) {
        this.dataLayerService.generateEvent('ga_event','drop','choose', '');
        this.files.push(...event.addedFiles);
        this.uploadFiles();
      }
    }
  }
}