<section class="hero">
  <div class="container converter-main">
    
      <div class="col column-ads"></div>
      <div class="col column-center">
        <div class="pronto text-center">
          <!--  start upload file -->
          <ngx-dropzone (change)="onDrop($event)" disableClick [ngStyle]="{'display': (progress == 'start') ? 'flex' : 'none'}">
            <div class="pronto__upload-file">
              <div class="pronto-upload">
                <div class="drop-zone">
                  <img src="/images/file.svg" alt="pdf" />
                </div>
                <app-button (onUploadClick)="onClick()" [title]="chooseFileBttn" [icon]="documentIcon"></app-button>
                <div class="p-1">or drop files here</div>
              </div>
              <div class="row secured-row">
                <div class="clickableArea" (mouseenter)="showSecurityPopup(true)" (mouseleave)="showSecurityPopup(false)">
                  <img alt="lock" src="/images/lock.svg" />
                  <span>Your files are secure</span>
                  <div [ngClass]="{'secured-popup': true, 'hide': popupStatus == false}">
                    Your files are only stored on our servers for 24 hours, after which they are permanently destroyed
                  </div>
                </div>
              </div>
            </div>
            <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" style="display:none;" />
          </ngx-dropzone>
          <!--  end upload file -->
          <!--  start Converting... -->
          <div class="pronto__converting p-2"  [ngStyle]="{'display': (progress == 'loading') ? 'flex' : 'none'}">
            <p class="title" #progressStatus>Converting...</p>
            <div class="m-auto">
              <img src="/images/loader.gif" alt="loader" />
            </div>
            <div class="cancel-progress" (click)="progress='cancel'">Cancel</div>
          </div>
          <!--  end Converting... -->
          <!--  start Cancel... -->
          <div class="pronto__cancel" [ngStyle]="{'display': (progress == 'cancel') ? 'flex' : 'none'}">
            <img src="/images/cancel.svg" alt="cancel" />
            <p>Are you sure you want to <br />
              cancel conversion?</p>
            <div class="button-wrapper">
              <button class="btn br-80" (click)="clearScreen('start')">Yes</button>
              <button class="btn btn-transparent br-80" (click)="progress='loading'">No</button>
            </div>
          </div>
          <!--  end Cancel... -->
          <!--  start Done... -->
          <div class="pronto__done" [ngStyle]="{'display': (progress == 'finished') ? 'flex' : 'none'}">
            <img class="done" src="/images/done.gif" alt="done" />
            <h3 class="title">Your file has been <br />
              downloaded</h3>
              <div>
                <button class="btn" (click)="clearScreen('start')">
                    <img src="/images/reload.svg" alt="reload">
                    <span>{{ startOver }}</span>
                </button>
              </div>
          </div>
          <!--  end Done... -->
          <!--  start Unvalid File... -->
          <div class="pronto__unvalid" [ngStyle]="{'display': (progress == 'alert') ? 'flex' : 'none'}">
            <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M45 0.312503C39.131 0.31045 33.3191 1.46492 27.8964 3.70995C22.4737 5.95498 17.5466 9.24656 13.3966 13.3966C9.24656 17.5466 5.95498 22.4737 3.70995 27.8964C1.46492 33.3191 0.31045 39.131 0.312503 45C0.31045 50.869 1.46492 56.6809 3.70995 62.1036C5.95498 67.5263 9.24656 72.4534 13.3966 76.6034C17.5466 80.7534 22.4737 84.045 27.8964 86.2901C33.3191 88.5351 39.131 89.6896 45 89.6875C50.869 89.6896 56.6809 88.5351 62.1036 86.2901C67.5263 84.045 72.4534 80.7534 76.6034 76.6034C80.7534 72.4534 84.045 67.5263 86.2901 62.1036C88.5351 56.6809 89.6896 50.869 89.6875 45C89.6896 39.131 88.5351 33.3191 86.2901 27.8964C84.045 22.4737 80.7534 17.5466 76.6034 13.3966C72.4534 9.24656 67.5263 5.95498 62.1036 3.70995C56.6809 1.46492 50.869 0.31045 45 0.312503ZM45 77.1875C43.6637 77.1906 42.3565 76.797 41.244 76.0564C40.1316 75.3159 39.264 74.2619 38.7512 73.0278C38.2384 71.7938 38.1034 70.4353 38.3633 69.1245C38.6233 67.8137 39.2665 66.6095 40.2114 65.6646C41.1564 64.7196 42.3605 64.0764 43.6714 63.8165C44.9822 63.5565 46.3407 63.6915 47.5747 64.2043C48.8088 64.7172 49.8628 65.5847 50.6033 66.6972C51.3438 67.8096 51.7375 69.1168 51.7344 70.4531C51.7303 72.2379 51.0194 73.9484 49.7574 75.2105C48.4953 76.4725 46.7848 77.1834 45 77.1875ZM53.1719 21.5625L50.625 55.9375C50.6091 56.1752 50.5034 56.3979 50.3293 56.5604C50.1551 56.7229 49.9257 56.813 49.6875 56.8125H40.3125C40.0743 56.813 39.8449 56.7229 39.6707 56.5604C39.4966 56.3979 39.3909 56.1752 39.375 55.9375L36.8281 21.5625C36.7591 20.4469 36.9193 19.3289 37.2987 18.2775C37.6782 17.2261 38.2689 16.2635 39.0345 15.4491C39.8001 14.6347 40.7243 13.9856 41.7503 13.542C42.7763 13.0983 43.8822 12.8694 45 12.8694C46.1178 12.8694 47.2237 13.0983 48.2497 13.542C49.2757 13.9856 50.1999 14.6347 50.9655 15.4491C51.7311 16.2635 52.3218 17.2261 52.7013 18.2775C53.0807 19.3289 53.2409 20.4469 53.1719 21.5625Z" fill="#D12D2D"/>
            </svg>
            <p>This file type you have <br />
              selected is not supported</p>
            <button (click)="clearScreen('start')" class="btn br-80">Start Over</button>
          </div>
          <!--  end Unvalid File... -->
          <!--  start Unvalid File... -->
          <div class="pronto__unvalid" [ngStyle]="{'display': (progress == 'multi') ? 'flex' : 'none'}">
            <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M45 0.312503C39.131 0.31045 33.3191 1.46492 27.8964 3.70995C22.4737 5.95498 17.5466 9.24656 13.3966 13.3966C9.24656 17.5466 5.95498 22.4737 3.70995 27.8964C1.46492 33.3191 0.31045 39.131 0.312503 45C0.31045 50.869 1.46492 56.6809 3.70995 62.1036C5.95498 67.5263 9.24656 72.4534 13.3966 76.6034C17.5466 80.7534 22.4737 84.045 27.8964 86.2901C33.3191 88.5351 39.131 89.6896 45 89.6875C50.869 89.6896 56.6809 88.5351 62.1036 86.2901C67.5263 84.045 72.4534 80.7534 76.6034 76.6034C80.7534 72.4534 84.045 67.5263 86.2901 62.1036C88.5351 56.6809 89.6896 50.869 89.6875 45C89.6896 39.131 88.5351 33.3191 86.2901 27.8964C84.045 22.4737 80.7534 17.5466 76.6034 13.3966C72.4534 9.24656 67.5263 5.95498 62.1036 3.70995C56.6809 1.46492 50.869 0.31045 45 0.312503ZM45 77.1875C43.6637 77.1906 42.3565 76.797 41.244 76.0564C40.1316 75.3159 39.264 74.2619 38.7512 73.0278C38.2384 71.7938 38.1034 70.4353 38.3633 69.1245C38.6233 67.8137 39.2665 66.6095 40.2114 65.6646C41.1564 64.7196 42.3605 64.0764 43.6714 63.8165C44.9822 63.5565 46.3407 63.6915 47.5747 64.2043C48.8088 64.7172 49.8628 65.5847 50.6033 66.6972C51.3438 67.8096 51.7375 69.1168 51.7344 70.4531C51.7303 72.2379 51.0194 73.9484 49.7574 75.2105C48.4953 76.4725 46.7848 77.1834 45 77.1875ZM53.1719 21.5625L50.625 55.9375C50.6091 56.1752 50.5034 56.3979 50.3293 56.5604C50.1551 56.7229 49.9257 56.813 49.6875 56.8125H40.3125C40.0743 56.813 39.8449 56.7229 39.6707 56.5604C39.4966 56.3979 39.3909 56.1752 39.375 55.9375L36.8281 21.5625C36.7591 20.4469 36.9193 19.3289 37.2987 18.2775C37.6782 17.2261 38.2689 16.2635 39.0345 15.4491C39.8001 14.6347 40.7243 13.9856 41.7503 13.542C42.7763 13.0983 43.8822 12.8694 45 12.8694C46.1178 12.8694 47.2237 13.0983 48.2497 13.542C49.2757 13.9856 50.1999 14.6347 50.9655 15.4491C51.7311 16.2635 52.3218 17.2261 52.7013 18.2775C53.0807 19.3289 53.2409 20.4469 53.1719 21.5625Z" fill="#D12D2D"/>
            </svg>
            <p>Uploading multiple files <br />
              is not supported</p>
            <button (click)="clearScreen('start')" class="btn br-80">Start Over</button>
          </div>
          <!--  end Unvalid File... -->
          <!--  start Unvalid File... -->
          <div class="pronto__unvalid" [ngStyle]="{'display': (progress == 'error') ? 'flex' : 'none'}">
            <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M45 0.312503C39.131 0.31045 33.3191 1.46492 27.8964 3.70995C22.4737 5.95498 17.5466 9.24656 13.3966 13.3966C9.24656 17.5466 5.95498 22.4737 3.70995 27.8964C1.46492 33.3191 0.31045 39.131 0.312503 45C0.31045 50.869 1.46492 56.6809 3.70995 62.1036C5.95498 67.5263 9.24656 72.4534 13.3966 76.6034C17.5466 80.7534 22.4737 84.045 27.8964 86.2901C33.3191 88.5351 39.131 89.6896 45 89.6875C50.869 89.6896 56.6809 88.5351 62.1036 86.2901C67.5263 84.045 72.4534 80.7534 76.6034 76.6034C80.7534 72.4534 84.045 67.5263 86.2901 62.1036C88.5351 56.6809 89.6896 50.869 89.6875 45C89.6896 39.131 88.5351 33.3191 86.2901 27.8964C84.045 22.4737 80.7534 17.5466 76.6034 13.3966C72.4534 9.24656 67.5263 5.95498 62.1036 3.70995C56.6809 1.46492 50.869 0.31045 45 0.312503ZM45 77.1875C43.6637 77.1906 42.3565 76.797 41.244 76.0564C40.1316 75.3159 39.264 74.2619 38.7512 73.0278C38.2384 71.7938 38.1034 70.4353 38.3633 69.1245C38.6233 67.8137 39.2665 66.6095 40.2114 65.6646C41.1564 64.7196 42.3605 64.0764 43.6714 63.8165C44.9822 63.5565 46.3407 63.6915 47.5747 64.2043C48.8088 64.7172 49.8628 65.5847 50.6033 66.6972C51.3438 67.8096 51.7375 69.1168 51.7344 70.4531C51.7303 72.2379 51.0194 73.9484 49.7574 75.2105C48.4953 76.4725 46.7848 77.1834 45 77.1875ZM53.1719 21.5625L50.625 55.9375C50.6091 56.1752 50.5034 56.3979 50.3293 56.5604C50.1551 56.7229 49.9257 56.813 49.6875 56.8125H40.3125C40.0743 56.813 39.8449 56.7229 39.6707 56.5604C39.4966 56.3979 39.3909 56.1752 39.375 55.9375L36.8281 21.5625C36.7591 20.4469 36.9193 19.3289 37.2987 18.2775C37.6782 17.2261 38.2689 16.2635 39.0345 15.4491C39.8001 14.6347 40.7243 13.9856 41.7503 13.542C42.7763 13.0983 43.8822 12.8694 45 12.8694C46.1178 12.8694 47.2237 13.0983 48.2497 13.542C49.2757 13.9856 50.1999 14.6347 50.9655 15.4491C51.7311 16.2635 52.3218 17.2261 52.7013 18.2775C53.0807 19.3289 53.2409 20.4469 53.1719 21.5625Z" fill="#D12D2D"/>
            </svg>
            <p>Error occurred.<br />
              Please, try again</p>
            <button (click)="clearScreen('start')" class="btn br-80">Start Over</button>
          </div>
          <!--  end Unvalid File... -->
        </div>
      </div>
      <div class="col column-ads text-right"></div>
   
  </div>
</section>