<div [ngClass]="{'mobile-hidden': extension}">
  <header>
    <div class="container header-main">

        <div class="header-logo">
            <img src="/images/logo.svg" alt="logo" />
            <span>Free PDF App</span>
        </div>
        <div class="text-right">
            <p>Convert files to & from PDF format</p>
        </div>
 
    </div>
  </header>
</div>

<div class="main">
  <section class="title-wrapper text-center">
    <div class="container">
    <h1 class="title">PDF Converter</h1>
    <p class="subtitle">Convert files from and to PDF</p>
    </div>
  </section>
  <app-converter></app-converter>
</div>

<footer>
  <div class="container">
    <div class="row footer">
      <div class="col-md-6">
        <ul>
          <li><a target="_blank" href="https://www.freepdfapp.com/privacy">Privacy</a></li>
          <li><a target="_blank" href="https://www.freepdfapp.com/eula">EULA</a></li>
          <li><a target="_blank" href="https://www.freepdfapp.com/uninstall">Uninstall</a></li>
        </ul>
      </div>
      <div class="col-md-6 text-right">
        <p>Copyright © Free PDF App 2022</p>
      </div>
    </div>
  </div>
</footer>